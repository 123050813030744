<template>
    <section class="gardenManagementPage">
        <g-breadcrumb bread="添加分类" />
        <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
            <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
                <el-form-item label="分类名称" label-width="100px" class="t-c">
                    <el-input v-model="noticeTitle" maxlength="35" placeholder="请输入分类名称" style="width: 210px;" :disabled="editor=='1'?true:false"/>
                </el-form-item>
            </div>
            <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
                <el-form-item label="所属部门" label-width="100px">
                    <el-select v-model="partName" placeholder="请选择部门" @change="(val) => partChangeType(val)" filterable
                        allow-create default-first-option :disabled="editor=='1'?true:false">
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.organizationName"
                            :value="item.id" />
                    </el-select>
                </el-form-item>
            </div>
            <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
                <el-form-item label="分类简介" label-width="100px">
                    <textarea id="content" name="content" rows="10" cols="40" style="width:75%;border: 1px solid #666;"
                        v-model="catInfo"></textarea>

                </el-form-item>
            </div>
            <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
            <el-form-item label="分类内容" label-width="100px">
                <Tinymce :height="200" v-model="tiny.value"></Tinymce>
            </el-form-item>
        </div>
        <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
            <el-form-item label="分类缩略图" label-width="100px">
                <uploadPics @fileList="fileList" :file-list.sync="fileLists" :limit="limit" @moveList="moveClick"></uploadPics>
            </el-form-item>
        </div>



        </el-form>


        <el-button @click="cancelClicks">取消</el-button>
        <el-button type="primary" @click="buttonClick">提交</el-button>





    </section>
</template>

<script>
import Tinymce from "./tinymce";
import uploadPics from '@/components/Upload/uploadPics.vue'
import uploadList from '@/components/Upload/uploadList.vue'
import {
    addPxNotice,
    queryPxNotice,
    updatePxCat,
    selectDcDept,
    addPxCat

} from '@api/bx/api_contentManagement';
import UeditorWrap from "vue-ueditor-wrap";
export default {
    components: { Tinymce, uploadPics, uploadList },
    data() {

        return {
            limit: 1,
            tiny: {
                value: undefined
            },
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10
            },

            typeList: [],
            piclist: {},
            carouselUrl: [],
            noticeTitle: '',
            editor: '0',
            partName: '',
            fileLists: [],
            catInfo: '',
            item: {}
        }
    },
    created() {
        this.editor = this.$route.query.editor
        this.selectDcDept()

        if (this.editor == '1') {
            this.item = JSON.parse(this.$route.query.items)
            console.log(this.item)
            this.noticeTitle = this.item.catName
            this.tiny.value = this.item.catContent
            this.partName = this.item.organizationId
            this.catInfo = this.item.catInfo
            this.fileLists = [{ name: '', url: this.item.catImg }],
                this.piclist.rootPath = this.item.catImg
        }
        // con
        // sole.log(this.editor)


    },
    methods: {
        partChangeType() {
            console.log(this.partName)
        },
        fileList(msg) {
            // this.fileLists=[]
            this.piclist = msg
            // console.log(msg)
        },
        // bannerList(msg) {
        //     this.carouselUrl = msg
        //     console.log(msg)
        // },
        moveClick(msg){
            // console.log(11111111111)
            // console.log(msg)
            this.piclist={}
          

        },
        cancelClicks(){
            // console.log('1111111')
            this.$router.push('/bxPxCatMenu/dcPxCatList')
            
        },
        buttonClick() {
            if(this.noticeTitle==''){
                this.$message.warning('请填写分类名称')
                return
            }
            if(this.partName==''){
                this.$message.warning('请选择所属部门')
                return
            }
            if(this.catInfo==''){
                this.$message.warning('请填写分类简介')
                return
            }
            if(this.tiny.value==undefined){
                this.$message.warning('请填写分类内容')
                return
            }
            if(JSON.stringify(this.piclist) === '{}'){
                this.$message.warning('请上传分类图')
                return
            }
            if (this.editor == '0') {
                // return
                //首次提交
                addPxCat({
                    catName: this.noticeTitle,
                    organizationId: this.partName,
                    catInfo: this.catInfo,
                    catContent: this.tiny.value,
                    catImg: this.piclist.rootPath,

                }).then(res => {
                    this.$message.success('上传成功')
                    this.$router.push('/bxPxCatMenu/dcPxCatList')

                })
            } else {
                //编辑提交
                updatePxCat({
                    catName: this.noticeTitle,
                    organizationId: this.partName,
                    catInfo: this.catInfo,
                    catContent: this.tiny.value,
                    catImg: this.piclist.rootPath,
                    id: this.item.id
                }).then(res => {
                    this.$message.success('编辑成功')
                    this.$router.push('/bxPxCatMenu/dcPxCatList')


                })

            }

        },
   




        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },


        // 部门列表
        selectDcDept() {
            selectDcDept({}).then(res => {
                this.typeList = res
            })
        },
        



    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

